.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: rgb(255,228,191);
  background: linear-gradient(353deg, rgba(255,228,191,1) 0%, rgba(255,213,154,1) 35%, rgba(255,194,108,1) 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#landing {
  min-height: 100vh;
  padding: 8px;
  background: rgb(255, 228, 191);
  background: linear-gradient(353deg, rgba(255, 228, 191, 1) 0%, rgba(255, 213, 154, 1) 35%, rgba(255, 194, 108, 1) 100%);
  padding-bottom: 64px;
}

#header {
  padding-top: 48px;
  min-height: 128px;
  width: 100vw;
}

@media screen and (max-width: 600px) {
  #header {
      padding-top: 16px;
    }
}


.landing-hero{
  margin-top:40px;
  width: 100%;
}

.thumb {
  opacity: 1;
  filter: blur(3px);
  transition: opacity 1s ease-in-out;
  position: absolute;
}

.loadOut {
  opacity: 0;
}


#about {
  min-height: 100vh;
  padding: 8px;
  background: rgb(5, 60, 94);
  background: linear-gradient(0deg, rgba(5, 60, 94, 1) 0%, rgba(21, 53, 73, 1) 65%, rgba(10, 122, 191, 1) 100%);
  padding-bottom: 64px;
}
