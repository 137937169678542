/*@font-face {
  font-family: Inter;
  src: local('Inter'), url(./fonts/Inter.ttf) format('truetype');
}

@font-face {
  font-family: Arimo;
  src: local('Arimo'), url(./fonts/Arimo.ttf) format('truetype');
}*/

body {
  font-family: 'Inter', 'Arimo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background: rgb(255,228,191);
  background: linear-gradient(353deg, rgba(255,228,191,1) 0%, rgba(255,213,154,1) 35%, rgba(255,194,108,1) 100%);*/
  margin: 0px;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
